import { useNavigate } from "react-router-dom";

export const useHistory = () => {
	const navigate = useNavigate();

	const push = (route: string) => navigate(route);

	const replace = (route: string) => navigate(route, { replace: true });

	const goBack = () => navigate(-1);

	const goForward = () => navigate(+1);

	return {
		push,
		goBack,
		replace,
		goForward,
	};
};
