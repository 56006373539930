/* eslint-disable no-console */

type InputUploadType = "image/*" | "*";

export const craftFileBuffer = (
	file: File,
	onResolve: (buffer: Buffer) => void,
) => {
	const reader = new FileReader();
	reader.onload = () => {
		const buffer = Buffer.from(
			(reader.result as string).split(",")[1],
			"base64",
		);
		onResolve(buffer);
	};
	reader.readAsDataURL(file);
};

export const asyncCraftFileBuffer = async (file: File) => new Promise<Buffer>((resolve) =>
	craftFileBuffer(file, resolve),
);
export const openFileInput = (
	onResolve: (file: File, url: string) => void,
	onError: (message: string) => void,
	inputType: InputUploadType,
) => {
	const input = document.createElement("input");
	input.setAttribute("type", "file");
	input.setAttribute("accept", inputType);
	input.click();
	input.onchange = () => {
		if (input.files && input.files.length > 0) {
			const file = input.files[0];
			const url = URL.createObjectURL(file);

			onResolve(file, url);
		} else {
			onError("Arquivo selecionado é inválido");
		}
	};
};
