import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";

export const ButtonBack: React.FC = observer(() => {
	const text = strings.actions.back;
	const history = useHistory();

	return (
		<Button
			onClick={() => history.goBack()}
			w={{
				base: "50%",
				md: "15%",
			}}
			mb={5}
			variant="outline"
			leftIcon={
				<ArrowBackIcon />
			}
		>
			{text}
		</Button>
	);
});
